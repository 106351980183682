import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Typography, Layout, DatePicker } from "antd";
import Chart from "chart.js";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { BASE_API } from "../config";
const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;

function Projects() {
  const history = useHistory();

  const [pieDataText, setPieDataText] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  var pieData = [];

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (!endDate || !startDate) return;
    else getFilterData();
  }, [endDate, startDate]);

  const getFilterData = async () => {
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios
      .get(
        `${BASE_API}/api/project/summary?start_date=${startDate}&end_date=${endDate}`,
        config
      )
      .then((res) => {
        setProjectInfo(res.data.data.data);
        setPieDataText(res.data.data.sums);
        pieData = res.data.data.sums;
        console.log(pieData);
        setLoading(false);
        chartFunc();
      })
      .catch((e) => console.log(e));
  };
  const getProjectData = async () => {
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios
      .get(`${BASE_API}/api/project/summary`, config)
      .then((res) => {
        setProjectInfo(res.data.data.data);
        setPieDataText(res.data.data.sums);
        pieData = res.data.data.sums;
        console.log(pieData);
        setLoading(false);
        chartFunc();
      })
      .catch((e) => console.log(e));
  };

  const chartFunc = () => {
    const acilmaOraniChart = document.getElementById("acilmaOrani");
    const randevuOraniChart = document.getElementById("ilkSecim");
    const onayDurumu = document.getElementById("onayDurumu");
    new Chart(acilmaOraniChart, {
      type: "pie",
      data: {
        labels: ["Açılan", "Açılmayan"],
        datasets: [
          {
            label: "Sales",
            data: [pieData.acilan_sayisi, pieData.acilmayan_sayisi],
            backgroundColor: ["#828cb5", "#F0F2F5"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#000000",
          },
        },
      },
    });
    new Chart(randevuOraniChart, {
      type: "pie",
      data: {
        labels: ["Evet", "Hayır", "Benim değil", "Henüz cevaplanmadı"],
        datasets: [
          {
            label: "Sales",
            data: [
              pieData.f1_secim1,
              pieData.f1_secim2,
              pieData.f1_secim3,
              //HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - 1.SAHNE CEVAPLAYAN KİŞİ SAYISI = HENUZ CEVAPLAMADI
              pieData.acilan_sayisi +
                pieData.acilmayan_sayisi -
                (pieData.f1_secim1 + pieData.f1_secim2 + pieData.f1_secim3),
            ],
            backgroundColor: ["#F6D5C6", "#ACDED5", "#487BA6", "#DE7474"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#000000",
          },
        },
      },
    });
    new Chart(onayDurumu, {
      type: "pie",
      data: {
        labels: ["Onaylandı", "Onaylanmadı"],
        datasets: [
          {
            label: "Sales",
            data: [
              pieData.f3_true,
              //HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - ONAYLAYAN KİŞİ SAYISI = ONAYLANMADI
              pieData.acilan_sayisi +
                pieData.acilmayan_sayisi -
                pieData.f3_true,
            ],
            backgroundColor: ["#FFD800", "#7D7D7D"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#000000",
          },
        },
      },
    });
  };

  const selectDate = (date, dateStr) => {
    setStartDate(dateStr[0]);
    setEndDate(dateStr[1]);
  };
  const columns = [
    {
      title: "Proje Adı",
      dataIndex: "title",
      key: "index",
    },
    {
      title: "Gönderilme Sayısı",
      dataIndex: "report",
      key: "index",
      render: (text) => {
        return <label>{text.toplam}</label>;
      },
    },
    {
      title: "İşlemler",
      width: "15%",
      align: "left",
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Button
              onClick={() =>
                history.push({
                  pathname: `/projects/detail/${text._id}`,
                  state: { projectId: text._id },
                })
              }
              style={{
                fontWeight: "bold",
                backgroundColor: "#292D3E",
                color: "#F0F2F5",
                width: "100%",
              }}
            >
              Projeye Git
            </Button>
            {/* <Button
              style={{
                fontWeight: 'bold',
                color: '#292D3E',
                backgroundColor: '#F0F2F5',
                marginLeft: '1%',
                width: '100%',
              }}
            >
              Excel Raporu
            </Button> */}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Row
        style={{
          marginTop: "3%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col span={22}>
          <Content
            style={{
              marginBottom: "2%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <RangePicker onChange={selectDate} />
          </Content>
        </Col>
        <Col span={22}>
          <Content
            style={{
              backgroundColor: "#FFFFFF",
              marginBottom: "2%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Content style={{ marginBottom: "0%" }}>
                <canvas id="acilmaOrani" width="225" height="225"></canvas>
              </Content>
              {pieDataText ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Açılan Davetiye Sayısı: {pieDataText.acilan_sayisi}
                  </label>
                  <label
                    style={{
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Açılmayan Davetiye Sayısı: {pieDataText.acilmayan_sayisi}
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "5%",
              }}
            >
              <Content style={{}}>
                <canvas id="ilkSecim" width="225" height="225"></canvas>
              </Content>
              {pieDataText ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Evet, randevu almak istiyorum : {pieDataText.f1_secim1}
                  </label>
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Hayır istemiyorum: {pieDataText.f1_secim2}
                  </label>
                  <label
                    style={{
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Bu araç, artık benim değil: {pieDataText.f1_secim3}
                  </label>
                  <label
                    style={{
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    {/* HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - 1.SAHNE CEVAPLAYAN KİŞİ SAYISI = HENUZ CEVAPLAMADI */}
                    Henüz cevaplanmadı:{" "}
                    {pieDataText.acilan_sayisi +
                      pieDataText.acilmayan_sayisi -
                      pieDataText.f1_secim1 -
                      pieDataText.f1_secim2 -
                      pieDataText.f1_secim3}
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "5%",
              }}
            >
              <Content style={{}}>
                <canvas id="onayDurumu" width="225" height="225"></canvas>
              </Content>
              {pieDataText ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Onaylayan kişi sayısı: {pieDataText.f3_true}
                  </label>
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    {/* HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - ONAYLAYAN KİŞİ SAYISI = ONAYLANMADI*/}
                    Onaylamayan kişi sayısı:{" "}
                    {pieDataText.acilan_sayisi +
                      pieDataText.acilmayan_sayisi -
                      pieDataText.f3_true}
                  </label>
                </div>
              ) : null}
            </div>
          </Content>
        </Col>
        <Col span={22} style={{ marginBottom: "3%" }}>
          <Table
            pagination={false}
            loading={isLoading}
            bordered
            dataSource={projectInfo}
            columns={columns}
            summary={(totalData) => {
              let totalSendCount = 0;

              totalData.forEach(({ report, repayment }) => {
                totalSendCount += report.toplam;
              });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <Text type="danger" style={{ fontWeight: "bold" }}>
                        Toplam
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type="danger" style={{ fontWeight: "bold" }}>
                        {totalSendCount}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="left">
                      <Button
                        onClick={() =>
                          axios
                            .get(`${BASE_API}/api/receipent/download-excel`)
                            .then(
                              (res) => (window.location.href = res.data.data)
                            )
                            .catch((e) => console.log(e))
                        }
                        style={{
                          fontWeight: "bold",
                          color: "#292D3E",
                          backgroundColor: "#F0F2F5",
                          width: "100%",
                        }}
                      >
                        Toplu Excel Raporu Al
                      </Button>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Projects;
