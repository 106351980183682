import React, { useState, useEffect } from 'react';
import { Card, Layout, Spin, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { projectList, projectSearchList } from '../redux/action';

const { Content } = Layout;
const { Search } = Input;

export default function HomePage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.Reducer.projectList);
  const pending = useSelector((state) => state.Reducer.loading);
  const failed = useSelector((state) => state.Reducer.failed);

  useEffect(() => {
    dispatch(projectList());
  }, []);

  const projectDeatil = (projectId) => {
    history.push({
      pathname: `/projects/detail/${projectId}`,
      state: { projectId: projectId },
    });
  };

  const searchProject = (value) => {
    dispatch(projectSearchList(value));
  };

  const CardProject = (i) => (
    <Card
      title={
        <span style={{ fontWeight: 'bold', fontSize: 16 }}>{i.title}</span>
      }
      onClick={() => projectDeatil(i._id)}
      headStyle={{
        padding: 0,
        backgroundColor: '#292D3E',
        textAlign: 'center',
        color: '#F0F2F5',
      }}
      bodyStyle={{ padding: 0 }}
      style={{
        backgroundColor: '#292D3E',
        cursor: 'pointer',
        margin: 10,
        border: 0,
        marginLeft: 20,
        padding: 10,
        width: '23%',
        boxShadow: '2px 2px 8px 2px #888',
      }}
      cover={
        <img
          alt="example"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/M%C3%BCnster%2C_Beresa%2C_Mercedes-Benz_C-Klasse_Cabrio_--_2018_--_1768.jpg/1200px-M%C3%BCnster%2C_Beresa%2C_Mercedes-Benz_C-Klasse_Cabrio_--_2018_--_1768.jpg"
        />
      }
    ></Card>
  );

  return (
    <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Search
        placeholder="Arayın..."
        onSearch={searchProject}
        style={{ margin: '1%' }}
      />
      {failed ? alert('HATALI. INTERNET BAĞLANTINIZI KONTROL EDIN.') : null}
      {pending ? (
        <div
          style={{
            marginTop: '20%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin indicator={<LoadingOutlined />} size="large" style={{}} />
        </div>
      ) : null}
      {projects.data ? projects.data.map((i) => CardProject(i)) : <></>}
    </Content>
  );
}
