import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'antd/dist/antd.css';
import store from './store';
import {
  HomePage,
  ListPage,
  Login,
  Templates,
  TemplateDetails,
  Projects,
} from './screens';
import { Menu } from './components';

const { Content, Header } = Layout;

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Route exact component={Login} path="/"></Route>
        {localStorage.getItem('userToken') ? (
          <Layout>
            <Header style={{ backgroundColor: '#292D3E' }}>
              <Menu />
            </Header>
            <Content>
              <Switch>
                <Route exact component={HomePage} path="/home"></Route>
                <Route
                  exact
                  component={ListPage}
                  path="/projects/detail/:projectId"
                ></Route>
                <Route exact component={Templates} path="/templates"></Route>
                <Route
                  exact
                  component={TemplateDetails}
                  path="/templates/detail/:templateId"
                ></Route>
                <Route exact component={Projects} path="/projects"></Route>
              </Switch>
            </Content>
          </Layout>
        ) : null}
      </Router>
    </Provider>
  );
}

export default App;
