import axios from 'axios';
import { BASE_API } from '../config';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const PROJECTS_LIST_PENDING = 'PROJECTS_LIST_PENDING';
export const PROJECTS_LIST_COMPLETED = ' PROJECTS_LIST_COMPLETED';
export const PROJECTS_LIST_FAILED = 'PROJECTS_LIST_FAILED';

export const RECEIPENT_LIST_PENDING = 'RECEIPENT_LIST_PENDING';
export const RECEIPENT_LIST_COMPLETED = 'RECEIPENT_LIST_COMPLETED';
export const RECEIPENT_LIST_FAILED = 'RECEIPENT_LIST_FAILED';

export const ADD_EXCEL_PENDING = 'ADD_EXCEL_PENDING';
export const ADD_EXCEL_COMPLETED = 'ADD_EXCEL_COMPLETED';
export const ADD_EXCEL_FAILED = 'ADD_EXCEL_FAILED';

const token = localStorage.getItem('userToken');
const config = {
  headers: {
    Authorization: 'Bearer ' + token,
  },
};

export function userLogin(data) {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_PENDING,
      });
      const response = await axios.post(`${BASE_API}/api/user/login`, data);
      dispatch({
        type: LOGIN_COMPLETED,
        payload: response.data,
      });
      localStorage.setItem('userToken', response.data.data.token);
      if (response.data.data.user.is_super_user === true) {
        localStorage.setItem('info', '184885xccsa84qwd4s');
      }
      return response;
    } catch (e) {
      dispatch({
        type: LOGIN_FAILED,
        payload: e.response ? e.response.data.message : e.message,
      });
      console.log(e);
      return e;
    }
  };
}

export function projectList() {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECTS_LIST_PENDING,
      });
      const response = await axios.get(`${BASE_API}/api/project`, config);
      dispatch({
        type: PROJECTS_LIST_COMPLETED,
        payload: response.data,
      });
      return response;
    } catch (e) {
      dispatch({
        type: PROJECTS_LIST_FAILED,
        payload: e.response ? e.response.data.message : e.message,
      });
      console.log(e);
      return e;
    }
  };
}

export function projectSearchList(title) {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECTS_LIST_PENDING,
      });
      const response = await axios.post(
        `${BASE_API}/api/project/search`,
        {
          title,
        },
        config,
      );
      dispatch({
        type: PROJECTS_LIST_COMPLETED,
        payload: response.data,
      });
      return response;
    } catch (e) {
      dispatch({
        type: PROJECTS_LIST_FAILED,
        payload: e.response ? e.response.data.message : e.message,
      });
      console.log(e);
      return e;
    }
  };
}

export function receipentList(project_id, currentPage, start_date, end_date) {
  let limit = 20;
  let skip = currentPage * limit - limit;

  let value = { project_id, skip, limit, start_date, end_date };
  return async (dispatch) => {
    try {
      dispatch({
        type: RECEIPENT_LIST_PENDING,
      });
      const response = await axios.post(
        `${BASE_API}/api/receipent/get`,
        value,
        config,
      );
      dispatch({
        type: RECEIPENT_LIST_COMPLETED,
        payload: response.data.data,
      });
      return response;
    } catch (e) {
      dispatch({
        type: RECEIPENT_LIST_FAILED,
        payload: e.response ? e.response.data.message : e.message,
      });
      console.log(e);
      return e;
    }
  };
}

export function receipentSearchList(
  name,
  project_id,
  currentPagination,
  start_date,
  end_date,
) {
  let limit = 20;
  let skip = currentPagination * limit - limit;
  let value = { name, project_id, skip, limit, start_date, end_date };
  return async (dispatch) => {
    try {
      dispatch({
        type: RECEIPENT_LIST_PENDING,
      });
      const response = await axios.post(
        `${BASE_API}/api/receipent/search`,
        value,
        config,
      );
      dispatch({
        type: RECEIPENT_LIST_COMPLETED,
        payload: response.data.data,
      });
      return response;
    } catch (e) {
      dispatch({
        type: RECEIPENT_LIST_FAILED,
        payload: e.response ? e.response.data.message : e.message,
      });
      console.log(e);
      return e;
    }
  };
}

// ŞU AN KULLANIMIYOR.
export function addExcelFile(formData) {
  let token = localStorage.getItem('userToken');
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-type': 'multipart/form-data',
    },
  };
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_EXCEL_PENDING,
      });
      const response = await axios.post(
        `${BASE_API}/api/receipent/upload-excel`,
        formData,
        config,
      );
      dispatch({
        type: ADD_EXCEL_COMPLETED,
        payload: response.data,
      });
      return response;
    } catch (e) {
      dispatch({
        type: ADD_EXCEL_FAILED,
        payload: e.response ? e.response.data.message : e.message,
      });
      console.log(e);
      return e;
    }
  };
}
