import React, { useEffect, useRef } from 'react';
import { Row, Col, Form, Divider, Input, Button, message } from 'antd';
import axios from 'axios';

import { BASE_API } from '../config';

function TemplateDetails(item) {
  const formRef = useRef();
  const templateId = item.location.state;

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    let token = localStorage.getItem('userToken');
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    await axios
      .get(
        `${BASE_API}/api/template/get?template_id=${templateId.template_id}`,
        config,
      )
      .then((res) => {
        formRef.current.setFieldsValue({ ...res.data.data });
      })
      .catch((e) => console.log(e));
  };

  const updateDetail = async (values) => {
    values['template_id'] = templateId.template_id;
    console.log(values);
    let token = localStorage.getItem('userToken');
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    await axios
      .post(`${BASE_API}/api/template/update`, values, config)
      .then(
        (res) => res.data.data.name && message.success('Güncelleme başarılı!'),
      )
      .catch((e) => message.error('Güncelleme başarısız!'));
  };

  return (
    <div>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '3%',
        }}
      >
        <Col style={{ backgroundColor: '#ffffff', padding: '1%' }} span={22}>
          <Col>ŞABLON DETAY</Col>
          <Divider />
          <Col>
            <Form ref={formRef} onFinish={updateDetail} layout="vertical">
              <Form.Item name="name" label="Şablon Adı:">
                <Input />
              </Form.Item>
              <Form.Item name="html" label="Şablon Video HTML:">
                <Input.TextArea rows={10} />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: '#292D3E',
                    color: '#F0F2F5',
                    border: 0,
                  }}
                  type="primary"
                >
                  KAYDET
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Col>
      </Row>
    </div>
  );
}

export default TemplateDetails;
