import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  message,
  Input,
  Form,
  Button,
  Row,
  Col,
  Modal,
  Select,
  DatePicker,
  Badge,
  Checkbox,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import Chart from "chart.js";
import { Table } from "ant-table-extensions";
import axios from "axios";
import moment from "moment";
import MaskedInput from "antd-mask-input";

import { BASE_API } from "../config";
import { receipentList, receipentSearchList } from "../redux/action";
import "./ListPage.css";
import TextArea from "antd/lib/input/TextArea";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

function ListPage(item) {
  const dispatch = useDispatch();
  const projectId = item.location.state;

  const receipentListData = useSelector((state) => state.Reducer.receipentList);
  const pending = useSelector((state) => state.Reducer.loading);

  const [newRecord, setNewRecord] = useState(false);
  const [excelModal, setExcelModal] = useState(false);
  const [privateText, setPrivateText] = useState(false);
  const [successNewRecord, setSuccessNewRecord] = useState([]);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pieDataText, setPieDataText] = useState([]);
  const [subeAdi, setSubeAdi] = useState("");
  const [excelFile, setExcelFile] = useState();
  const [excelAddCompleted, setExcelAddCompleted] = useState({});

  var pieData = [];
  var formData = new FormData();
  var formRef = useRef();

  useEffect(() => {
    dispatch(
      receipentList(projectId.projectId, currentPagination, startDate, endDate)
    );
    if (pieDataText.length < 1) getPieData();
  }, [
    excelAddCompleted,
    successNewRecord,
    currentPagination,
    startDate,
    endDate,
  ]);

  const changeFile = async (value) => {
    setExcelFile(value.target.files[0]);
  };

  const receipentSearch = async (name) => {
    let project_id = projectId.projectId;
    await dispatch(
      receipentSearchList(
        name,
        project_id,
        currentPagination,
        startDate,
        endDate
      )
    );
  };

  function dateSelect(date, dateString) {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  }

  const newRecordSubmit = async (values) => {
    values = JSON.parse(JSON.stringify(values).replace(/"\s+|\s+"/g, '"'));
    values["project_id"] = projectId.projectId;
    values["sube"] = subeAdi;
    console.log(values);
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios
      .post(`${BASE_API}/api/receipent/create`, values, config)
      .then((res) => {
        console.log(res.data);
        if (res.data.data.firstName) {
          message.success("Kişi başarıyla eklendi.");
          setSuccessNewRecord(res.data.data);
        } else {
          message.error("Kişi eklenirken bir sorun oluştu. Tekrar deneyin.");
        }
      })
      .catch((e) =>
        message.error("Kişi eklenirken bir sorun oluştu. Tekrar deneyin.")
      );
    formRef.current.setFieldsValue({
      firstName: undefined,
      lastName: undefined,
      phone_number: undefined,
      email: undefined,
      plaka: undefined,
      km: undefined,
      sube_id: undefined,
      actionText: undefined,
    });
    setNewRecord(false);
  };

  const currentPage = (current) => {
    setCurrentPagination(current);
    console.log(current);
  };

  const getPieData = async () => {
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    await axios
      .get(
        `${BASE_API}/api/receipent/getReport?project_id=${projectId.projectId}`,
        config
      )
      .then((res) => {
        setPieDataText(res.data.data);
        pieData = res.data.data;
        chartFunc();
      })
      .catch((e) => console.log(e));
  };

  const excelSend = async (value) => {
    let token = localStorage.getItem("userToken");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "multipart/form-data",
      },
    };
    await formData.append("file", excelFile);
    await formData.append("project_id", projectId.projectId);
    if (privateText) await formData.append("actionText", value.actionText);
    await axios
      .post(`${BASE_API}/api/receipent/upload-excel`, formData, config)
      .then((res) => {
        if (res.data.data.status === false) {
          message.error(res.data.data.message);
        } else {
          message.success("Dosya yükleme başarılı");
        }
        setExcelAddCompleted(res.data);
      })
      .catch((e) => {
        message.error("Dosya yüklerken bir sorun oluştu");
        setExcelAddCompleted(e);
      });
    setExcelModal(false);
  };

  const excelExport = async () => {
    window.location.href = `${BASE_API}/api/receipent/download-excel?project_id=${projectId.projectId}`;
  };

  const chartFunc = async () => {
    const acilmaOraniChart = document.getElementById("acilmaOrani");
    const randevuOraniChart = document.getElementById("ilkSecim");
    const onayDurumu = document.getElementById("onayDurumu");
    new Chart(acilmaOraniChart, {
      type: "pie",
      data: {
        labels: ["Açılan", "Açılmayan"],
        datasets: [
          {
            label: "Sales",
            data: [pieData.acilan_sayisi, pieData.acilmayan_sayisi],
            backgroundColor: ["#828cb5", "#F0F2F5"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#000000",
          },
        },
      },
    });
    new Chart(randevuOraniChart, {
      type: "pie",
      data: {
        labels: ["Evet", "Hayır", "Benim değil", "Henüz cevaplanmadı"],
        datasets: [
          {
            label: "Sales",
            data: [
              pieData.f1_secim1,
              pieData.f1_secim2,
              pieData.f1_secim3,
              pieData.acilan_sayisi + //HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - 1.SAHNE CEVAPLAYAN KİŞİ SAYISI = HENUZ CEVAPLAMADI
                pieData.acilmayan_sayisi -
                (pieData.f1_secim1 + pieData.f1_secim2 + pieData.f1_secim3),
            ],
            backgroundColor: ["#F6D5C6", "#ACDED5", "#487BA6", "#DE7474"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#000000",
          },
        },
      },
    });
    new Chart(onayDurumu, {
      type: "pie",
      data: {
        labels: ["Onaylandı", "Onaylanmadı"],
        datasets: [
          {
            label: "Sales",
            data: [
              pieData.f3_true,
              pieData.acilan_sayisi +
                pieData.acilmayan_sayisi -
                pieData.f3_true,
            ],
            backgroundColor: ["#FFD800", "#7D7D7D"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: "#000000",
          },
        },
      },
    });
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "createdAt",
      key: "_id",
      render: (invoice_date) => {
        return (
          <label style={{}}>{moment(invoice_date).format("DD.MM.YYYY")}</label>
        );
      },
    },
    {
      title: "Ad",
      dataIndex: "firstName",
    },
    {
      title: "Soyad",
      dataIndex: "lastName",
    },
    {
      title: "Telefon Numarası",
      dataIndex: "phone_number",
    },
    {
      title: "E-Posta",
      dataIndex: "email",
    },
    {
      title: "Ek Bilgiler",
      dataIndex: "additional_data",
      children: [
        {
          title: "Kilometre",
          key: "index",
          render: (text, record, index) =>
            receipentListData.receipents[index].additional_data.km,
        },
        // {
        //   title: "Özel Metin",
        //   key: "index",
        //   render: (text, record, index) =>
        //     receipentListData.receipents[index].additional_data.actionText,
        // },
        {
          title: "Şube",
          key: "index",
          render: (text, record, index) =>
            receipentListData.receipents[index].additional_data.sube,
        },
        {
          title: "Plaka",
          key: "index",
          render: (text, record, index) =>
            receipentListData.receipents[index].additional_data.plaka,
        },
        {
          title: "Video İzlenme",
          key: "index",
          align: "center",
          render: (text, record, index) => (
            <span>
              {receipentListData.receipents[index].additional_data.hasOpened ===
              true ? (
                <Badge status="success" />
              ) : (
                <Badge status="error" />
              )}
            </span>
          ),
        },
        {
          title: "Randevu Onay",
          key: "index",
          align: "center",
          render: (text, record, index) => (
            <span>
              {receipentListData.receipents[index].additional_data.approved ===
              true ? (
                <div
                  title={
                    "Randevu tarihi: \n" +
                    moment(
                      receipentListData.receipents[index].additional_data.f2
                    ).format("DD-MM-YYYY")
                  }
                >
                  <Badge status="success" />
                </div>
              ) : (
                <div title={"Randevu onaylanmadı."}>
                  <Badge size="default" status="error" />
                </div>
              )}
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <div>
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <Col span={23}>
          <Content
            style={{
              marginLeft: "1%",
              marginRight: "1%",
              backgroundColor: "#FFFFFF",
              marginTop: "1%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Content style={{ marginBottom: "0%" }}>
                <canvas id="acilmaOrani" width="225" height="225"></canvas>
              </Content>
              {pieDataText ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Açılan Davetiye Sayısı: {pieDataText.acilan_sayisi}
                  </label>
                  <label
                    style={{
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Açılmayan Davetiye Sayısı: {pieDataText.acilmayan_sayisi}
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "5%",
              }}
            >
              <Content style={{}}>
                <canvas id="ilkSecim" width="225" height="225"></canvas>
              </Content>
              {pieDataText ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Randevu almak istiyorum: {pieDataText.f1_secim1}
                  </label>
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Hayır istemiyorum: {pieDataText.f1_secim2}
                  </label>
                  <label
                    style={{
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Bu araç, artık benim değil: {pieDataText.f1_secim3}
                  </label>
                  <label
                    style={{
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Henüz cevaplanmadı:{" "}
                    {/*HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - 1.SAHNE CEVAPLAYAN KİŞİ SAYISI = HENUZ CEVAPLAMADI*/}
                    {pieDataText.acilan_sayisi +
                      pieDataText.acilmayan_sayisi -
                      pieDataText.f1_secim1 -
                      pieDataText.f1_secim2 -
                      pieDataText.f1_secim3}
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "5%",
              }}
            >
              <Content style={{}}>
                <canvas id="onayDurumu" width="225" height="225"></canvas>
              </Content>
              {pieDataText ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Onaylayan kişi sayısı: {pieDataText.f3_true}
                  </label>
                  <label
                    style={{
                      marginLeft: "2%",
                      marginTop: "2%",
                      color: "#000000",
                      width: "100%",
                    }}
                  >
                    Onaylamayan kişi sayısı:{" "}
                    {/*HESAPLAMA => TOPLAM GÖNDERİLEN DAVETİYE - ONAYLAYAN KİŞİ SAYISI = ONAYLAMAYAN KİŞİ SAYISI*/}
                    {pieDataText.acilan_sayisi +
                      pieDataText.acilmayan_sayisi -
                      pieDataText.f3_true}
                  </label>
                </div>
              ) : null}
            </div>
          </Content>
        </Col>
      </Row>
      <Modal
        title="Yeni Kişi Ekle"
        onCancel={() => setNewRecord(false)}
        visible={newRecord}
        footer={[
          <Button
            style={{ backgroundColor: "#292D3E", color: "#F0F2F5" }}
            form="myForm"
            key="submit"
            htmlType="submit"
          >
            Gönder
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          id="myForm"
          name="basic"
          ref={formRef}
          onFinish={newRecordSubmit}
        >
          <Form.Item
            label="Ad"
            name="firstName"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                min: 3,
                message: "Ad en az 3 karakter olmalıdır!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Soyad"
            name="lastName"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: "Soyad en az 2 karakter olmalıdır!",
                min: 2,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefon"
            name="phone_number"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: "Geçerli bir telefon numarası giriniz!",
                min: 10,
              },
            ]}
          >
            <MaskedInput mask="+90(111) 111 11 11" />
          </Form.Item>
          <Form.Item
            label="E posta"
            name="email"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                type: "email",
                message: "Geçerli bir e-posta adresi giriniz.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Plaka"
            name="plaka"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: "Geçerli bir plaka numarası giriniz!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Kilometre (Yıllık bakım için 0 giriniz.)"
            name="km"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: "Geçerli bir kilometre giriniz.",
                min: 0,
              },
            ]}
          >
            <Input type="number" min={0} />
          </Form.Item>
          <Form.Item
            label="Şube"
            name="sube_id"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: "Şube işaretlemesi yapınız!",
              },
            ]}
          >
            <Select
              onChange={(text, index) => {
                setSubeAdi(index.children);
              }}
            >
              <Option value="GS0002384">Koluman Tarsus</Option>
              <Option value="GS0050336">Koluman İstanbul Büyükdere</Option>
              <Option value="GS0002393">Koluman İstanbul Samandıra</Option>
              <Option value="GS0002376">Koluman Balgat</Option>
              {/* <Option value="GS0019658">Koluman Gölbaşı</Option> */}
              <Option value="GS0079461">Koluman Gaziantep İpekyolu</Option>
              <Option value="GS0002372">Koluman Gaziantep</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Checkbox onChange={(e) => setPrivateText(e.target.checked)}>
              Özel metin ekle
            </Checkbox>
          </Form.Item>
          {privateText && (
            <Form.Item
              name="actionText"
              label="Özel metin"
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: "Özel metin boş bırakılamaz!",
                },
              ]}
            >
              <TextArea
                maxLength={100}
                placeholder="Özel metni giriniz."
                rows={3}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        title="Dosya ekle"
        onCancel={() => setExcelModal(false)}
        footer={[
          <Button
            key="submit"
            style={{ backgroundColor: "#292D3E", color: "#F0F2F5" }}
            form="form"
            htmlType="submit"
          >
            Gönder
          </Button>,
        ]}
        visible={excelModal}
      >
        <Form layout="vertical" id="form" onFinish={excelSend}>
          <Form.Item>
            <Input onChange={changeFile} type="file" accept=".xlsx" />
          </Form.Item>
          <Form.Item>
            <Checkbox onChange={(e) => setPrivateText(e.target.checked)}>
              Özel metin ekle
            </Checkbox>
          </Form.Item>
          {privateText && (
            <Form.Item
              name="actionText"
              label="Özel metin"
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: "Özel metin boş bırakalamaz!",
                },
              ]}
            >
              <TextArea
                maxLength={100}
                placeholder="Özel metni giriniz."
                rows={3}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Row>
        <Col span={24}>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginLeft: "3%",
              marginRight: "3%",
              marginTop: "2%",
              marginBottom: "2%",
            }}
          >
            <Content
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <RangePicker
                style={{ height: "1%", width: "18%" }}
                onChange={dateSelect}
              />
              <Search
                placeholder="Kişinin adına göre arayın..."
                onSearch={receipentSearch}
                style={{ marginLeft: "1%", marginRight: "1%" }}
              />
              <Button
                onClick={() => setNewRecord(true)}
                style={{
                  width: "10%",
                  float: "right",
                  marginBottom: 10,
                  color: "#F0F2F5",
                  backgroundColor: "#292D3E",
                  marginRight: "1%",
                }}
              >
                Kişi Ekle
              </Button>
              <Button
                onClick={() => setExcelModal(true)}
                style={{
                  width: "10%",
                  marginRight: "1%",

                  float: "right",
                  marginBottom: 10,
                  color: "#F0F2F5",
                  backgroundColor: "#292D3E",
                }}
              >
                Dosya Ekle
              </Button>
              <Button
                onClick={() => excelExport()}
                style={{
                  width: "10%",
                  float: "right",
                  marginBottom: 10,
                  color: "#F0F2F5",
                  backgroundColor: "#292D3E",
                  visibility: "hidden",
                }}
              >
                Çıktı Al
              </Button>
            </Content>
            <Table
              bordered
              locale={{ emptyText: "Kişi bulunmamaktadır." }}
              expandable={{
                expandedRowRender: (record) => {
                  if (record.additional_data.hasOpened) {
                    if (record.additional_data.f1 === 3) {
                      return (
                        <label style={{ fontWeight: "bold" }}>
                          Video açıldı. Ancak kullanıcı herhangi bir seçim
                          yapmadı.
                        </label>
                      );
                    }
                    if (record.additional_data.f3) {
                      let additional_date = moment(
                        record.additional_data.f2
                      ).format("DD.MM.YYYY HH:mm");
                      return (
                        <label
                          style={{
                            color: "#292D3E",
                            fontWeight: "bold",
                          }}
                        >
                          Randevu tarihi:{" "}
                          <label
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            {additional_date}
                          </label>
                          {/* <br></br>
                          <label style={{ fontWeight: 'bold' }}>
                            Şasi No:
                            <label style={{ fontWeight: 'normal' }}>
                              {' '}
                              {record.additional_data.sasiNo}
                            </label>
                          </label> */}
                        </label>
                      );
                    } else {
                      if (record.additional_data.f1 === 0) {
                        return (
                          <label style={{ fontWeight: "bold" }}>
                            Kullanıcı randevu almak istediğini belirtti ancak
                            herhangi bir tarih onaylamadı.
                          </label>
                        );
                      } else if (record.additional_data.f1 === 1) {
                        return (
                          <label style={{ fontWeight: "bold" }}>
                            Kullanıcının cevabı:{" "}
                            <label
                              style={{
                                fontWeight: "normal",
                              }}
                            >
                              Hayır teşekkürler.
                            </label>
                          </label>
                        );
                      } else if (record.additional_data.f1 === 2) {
                        return (
                          <label style={{ fontWeight: "bold" }}>
                            Kullanıcının cevabı:{" "}
                            <label
                              style={{
                                fontWeight: "normal",
                              }}
                            >
                              Bu araç, artık bana ait değil.
                            </label>
                          </label>
                        );
                      }
                    }
                  } else {
                    return (
                      <label
                        style={{
                          color: "#292D3E",
                          fontWeight: "bold",
                        }}
                      >
                        Video henüz açılmadı.
                      </label>
                    );
                  }
                },
                rowExpandable: (record) => record.name !== "Not Expandable",
              }}
              loading={pending}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              rowKey="_id"
              columns={columns}
              dataSource={receipentListData.receipents}
              pagination={{
                onChange: currentPage,
                current: currentPagination,
                total: receipentListData.count,
                pageSize: 20,
              }}
            />
          </Content>
        </Col>
      </Row>
    </div>
  );
}

export default ListPage;
