import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './Signin.css';
import { userLogin } from '../redux/action';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 2,
    span: 16,
  },
};

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.Reducer.login);
  const loading = useSelector((state) => state.Reducer.loading);
  const failed = useSelector((state) => state.Reducer.failed);

  useEffect(() => {
    if (localStorage.getItem('info')) {
      history.push('/projects');
    } else if (localStorage.getItem('userToken')) {
      history.push('/home');
    }
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    dispatch(userLogin(values));
  };

  const successLogin = () => {
    window.location.href = '/home';
  };

  return (
    <div className="signup-main">
      {Object.keys(loginState).length > 0
        ? successLogin()
        : failed && alert('Giriş başarısız!')}
      <div className="form-wrapper">
        <h3 className="form-header">Giriş</h3>
        <p className="form-info">
          Giriş yapmak için hesap bilgilerinizi girin.
        </p>
        <Form {...layout} name="basic" onFinish={onFinish}>
          <Form.Item
            label="E-posta"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Geçerli bir e-posta adresi girin!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Şifre"
            name="password"
            rules={[
              {
                required: true,
                message: 'Parola boş bırakılamaz!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className="form-footer">
            <Form.Item {...tailLayout}>
              <Button
                style={{ backgroundColor: '#292D3E', color: '#F0F2F5' }}
                htmlType="submit"
                loading={loading}
              >
                GİRİŞ
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}
