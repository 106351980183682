import {
  LOGIN_COMPLETED,
  LOGIN_FAILED,
  LOGIN_PENDING,
  PROJECTS_LIST_COMPLETED,
  PROJECTS_LIST_FAILED,
  PROJECTS_LIST_PENDING,
  RECEIPENT_LIST_COMPLETED,
  RECEIPENT_LIST_FAILED,
  RECEIPENT_LIST_PENDING,
  ADD_EXCEL_COMPLETED,
  ADD_EXCEL_FAILED,
  ADD_EXCEL_PENDING,
} from './action';

const initialState = {
  login: {},
  projectList: [],
  receipentList: [],
  addExcel: {},
  errMsg: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        login: {},
      };
    case LOGIN_COMPLETED:
      return {
        ...state,
        loading: false,
        failed: false,
        login: action.payload,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        login: {},
        errMsg: action.payload,
      };
    case PROJECTS_LIST_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        projectList: [],
      };
    case PROJECTS_LIST_COMPLETED:
      return {
        ...state,
        loading: false,
        failed: false,
        projectList: action.payload,
      };
    case PROJECTS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        projectList: [],
        errMsg: action.payload,
      };
    case RECEIPENT_LIST_PENDING:
      return {
        ...state,
        loading: true,
        failed: false,
        receipentList: [],
      };
    case RECEIPENT_LIST_COMPLETED:
      return {
        ...state,
        loading: false,
        failed: false,
        receipentList: action.payload,
      };
    case RECEIPENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        receipentList: [],
        errMsg: action.payload,
      };
    case ADD_EXCEL_PENDING: //EXCEL REDUCERLARI ŞU AN KULLANILMIYOR
      return {
        ...state,
        loading: true,
        failed: false,
        addExcel: {},
      };
    case ADD_EXCEL_COMPLETED:
      return {
        ...state,
        loading: false,
        failed: false,
        addExcel: action.payload,
      };
    case ADD_EXCEL_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
        addExcel: {},
        errMsg: action.payload,
      };
    default:
      return state;
  }
};
