import React, { useState, useEffect } from 'react';
import { Table, Row, Form, Col, Button, Modal, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { BASE_API } from '../config';

function Templates() {
  const [templatesData, setTemplatesData] = useState();
  const [deleteTemplateData, setDeleteTemplateData] = useState();
  const [createTemplate, setCreateTemplate] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getTemplates();
  }, [deleteTemplateData, createTemplate]);

  const getTemplates = async () => {
    let token = localStorage.getItem('userToken');
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    await axios
      .get(`${BASE_API}/api/template/`, config)
      .then((res) => {
        console.log(res.data.data);
        setTemplatesData(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const templateCreate = async (values) => {
    let token = localStorage.getItem('userToken');
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    };
    await axios
      .post(`${BASE_API}/api/template/create`, values, config)
      .then((res) => {
        if (res.data.data.name) message.success('Şablon eklendi.');
        setCreateTemplate(res.data.data);
      })
      .catch((e) => console.log(e));
    setModalVisible(false);
  };

  const deleteTemplate = async (template_id) => {
    let token = localStorage.getItem('userToken');
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: { template_id: template_id },
    };
    await axios
      .delete(`${BASE_API}/api/template/delete`, config)
      .then((res) => setDeleteTemplateData(res.data))
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      title: 'Şablon Adı',
      dataIndex: 'name',
    },
    {
      title: 'İşlemler',
      dataIndex: '_id',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <Button
              onClick={() =>
                history.push({
                  pathname: `/templates/detail/${text}`,
                  state: { template_id: text },
                })
              }
              style={{
                fontWeight: 'bold',
                backgroundColor: '#292D3E',
                color: '#F0F2F5',
              }}
            >
              Detay
            </Button>
            <Button
              onClick={() => {
                deleteTemplate(text);
              }}
              style={{
                fontWeight: 'bold',
                color: '#292D3E',
                backgroundColor: '#F0F2F5',
                marginLeft: '1%',
              }}
            >
              Sil
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        title="Şablon Ekle"
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
        footer={[
          <Button
            key="submit"
            style={{ backgroundColor: '#292D3E', color: '#F0F2F5' }}
            form="form"
            htmlType="submit"
          >
            Şablon Ekle
          </Button>,
        ]}
      >
        <Form onFinish={templateCreate} name="form" layout="vertical">
          <Form.Item name="name" label="Şablon Adı">
            <Input />
          </Form.Item>
          <Form.Item name="html" label="HTML">
            <Input.TextArea rows={10} />
          </Form.Item>
        </Form>
      </Modal>
      <Row
        style={{
          marginTop: '3%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col span={22}>
          <Button
            onClick={() => setModalVisible(true)}
            style={{
              marginBottom: '1%',
              float: 'right',
              backgroundColor: '#292D3E',
              color: '#F0F2F5',
            }}
          >
            Şablon Ekle
          </Button>
          <Table
            // title={() => 'ŞABLONLAR'}
            dataSource={templatesData}
            columns={columns}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Templates;
