import React from 'react';
import { Layout, Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
const { Header } = Layout;

export default function MenuList() {
  const Logout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('info');
    window.location.href = '/';
  };
  if (localStorage.getItem('info')) {
    return (
      <Header style={{ backgroundColor: '#292D3E' }}>
        <Menu
          style={{ backgroundColor: '#292D3E' }}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
        >
          <Menu.Item
            style={{
              fontSize: 16,
              color: '#F0F2F5',
              fontWeight: 'bold',
            }}
            onClick={() => (window.location.href = '/projects')}
          >
            Proje Listesi
          </Menu.Item>
          <Menu.Item
            style={{
              visibility: 'hidden',
              fontSize: 16,
              color: '#F0F2F5',
              fontWeight: 'bold',
            }}
            onClick={() => (window.location.href = '/templates')}
          >
            Şablon Listesi
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              color: '#F0F2F5',
              float: 'right',
            }}
            onClick={Logout}
          >
            <LogoutOutlined style={{ fontSize: 16 }} />
            Çıkış
          </Menu.Item>
        </Menu>
      </Header>
    );
  } else {
    return (
      <Header style={{ backgroundColor: '#292D3E' }}>
        <Menu
          style={{ backgroundColor: '#292D3E' }}
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
        >
          <Menu.Item
            style={{ fontSize: 16, color: '#F0F2F5', fontWeight: 'bold' }}
            onClick={() => (window.location.href = '/home')}
          >
            Anasayfa
          </Menu.Item>
          <Menu.Item
            style={{
              visibility: 'hidden',
              fontSize: 16,
              color: '#F0F2F5',
              fontWeight: 'bold',
            }}
            onClick={() => (window.location.href = '/templates')}
          >
            Şablon Listesi
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              color: '#F0F2F5',
              float: 'right',
            }}
            onClick={Logout}
          >
            <LogoutOutlined style={{ fontSize: 16 }} />
            Çıkış
          </Menu.Item>
        </Menu>
      </Header>
    );
  }
}
